<script>
  import CategoriesFilter from "./CategoriesFilter.svelte";
  import KeywordFilter from "./KeywordFilter.svelte";
  import BrandsFilter from "./BrandsFilter.svelte";

  import { createEventDispatcher } from "svelte";

  import { prepend, clone, remove } from "ramda/src";

  import { user } from "../../stores";

  export let isEventsSidepaneOn;
  export let dsaFilterActive = false;
  export let dsaFilter = [];
  export let categoriesInDataset;
  export let categoriesFilter;
  export let brandsFilter;
  export let highlightedBrand;
  export let keywordFilter;
  export let isNewsFilter;
  export let isVacancyFilter = false
  export let isFollowedFilter;
  export let isGrantedFilter;
  export let dsaHovered;
  export let dsaSelected = [];

  const dispatch = createEventDispatcher();
  const toggleDsaFilterActive = () => {
    dsaFilterActive = !dsaFilterActive;
  };
  const requestToggleDsaFilterShown = () => {
    if (dsaFilterActive) dispatch("toggleDsaFilterShownRequested");
  };
  const handleDsaHovered = (dsa) => {
    dsaHovered = dsa;
  };
  const handleDsaUnhovered = () => {
    dsaHovered = null;
  };
  const toggleDsaSelected = (dsa) => {
    dsaSelected = dsaSelected.includes(dsa)
      ? dsaSelected.filter((item) => item !== dsa)
      : prepend(dsa, dsaSelected);
  };
  const toggleNewsFilter = () => {
    isNewsFilter = !isNewsFilter;
  };
  const toggleIsFollowedFilter = () => {
    isFollowedFilter = !isFollowedFilter;
  };
  const toggleIsGrantedFilter = () => {
    isGrantedFilter = !isGrantedFilter;
  };
  const toggleVacancyFilter = () => {
    isVacancyFilter = !isVacancyFilter
  }
</script>

<style>
  .frame {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin: 0 0 5px;
    padding: 0 0 5px; */
    max-width: calc(100vw - 20px);
  }
  .frame .categories-and-keyword-frame {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .frame .categories-and-keyword-frame .full-width {
    width: 100%;
    margin: 0;
    padding: 10px 0 0;
  }
  .frame .categories-and-keyword-frame .keyword-filter-frame {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    min-width: 300px;
    margin: 0 3px 0 0;
    flex: 1;
  }
  .frame .categories-and-keyword-frame .keyword-filter-frame .icon {
    position: relative;
    height: 33px;
    width: 33px;
    margin: 0 3px 0 0;
    background-color: rgba(236, 240, 240, 1);
    border-radius: 3px;
  }
  .frame .categories-and-keyword-frame .keyword-filter-frame .icon img {
    position: absolute;
    height: 22px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .frame .categories-and-keyword-frame .categories-filter-frame {
    flex: 1;
  }
  .frame .dsa-filter-frame {
    flex: 1;
    margin: 10px 0 0;
    padding: 5px;
    background-color: #ecf0f1;
    display: flex;
  }
  .frame .dsa-filter-frame .dsa-filter-action-buttons {
    display: flex;
  }
  .frame .dsa-filter-frame .dsa-filter-action-buttons .button {
    height: 35px;
    width: 40px;
    margin-right: 5px;
    border: 1px solid white;
    background-color: white;
    padding: 3px;
    border-radius: 3px;
    overflow: hidden;
    cursor: pointer;
  }
  .frame .dsa-filter-frame .dsa-filter-action-buttons .button--inactive {
    cursor: default;
    opacity: 0.5;
  }
  .frame .dsa-filter-frame .dsa-filter-action-buttons .button img {
    height: 25px;
    width: 30px;
    object-fit: contain;
  }
  .frame .dsa-filter-frame .dsa-filter-carousel {
    flex: 1;
    background-color: rgb(255, 255, 255);
    padding: 1px;
    display: flex;
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: center;
  }
  .frame .dsa-filter-frame .dsa-filter-carousel--inactive {
    opacity: 0.5;
    cursor: pointer;
    pointer-events: none;
  }
  .frame .dsa-filter-frame .dsa-filter-carousel .dsa {
    flex: 0 0 auto;
    cursor: pointer;
    margin: 1px 2px;
    padding: 1px;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    opacity: 0.5;
    height: fit-content;
  }
  .frame .dsa-filter-frame .dsa-filter-carousel .dsa--m {
    background-color: #5289d1;
  }
  .frame .dsa-filter-frame .dsa-filter-carousel .dsa--w {
    background-color: #eb5c5c;
  }
  .frame .dsa-filter-frame .dsa-filter-carousel .dsa--x {
    background-color: #daa520;
  }
  .frame .dsa-filter-frame .dsa-filter-carousel .dsa--hovered {
    color: white;
    opacity: 1;
  }
  .frame .dsa-filter-frame .dsa-filter-carousel .dsa--selected {
    color: white;
    opacity: 1;
  }
  .frame .dsa-filter-frame .dsa-filter-carousel .text {
    font-size: 15px;
    margin: 0 5px;
    user-select: none;
    opacity: 0.6;
  }
  .frame .brand-filters-frame {
  }
</style>

<div class="frame">
  <div class="categories-and-keyword-frame">
    <div class="keyword-filter-frame {!isEventsSidepaneOn ? 'full-width' : ''}">
      {#if $user.role !== 'guest'}
        <!-- <div class="icon" on:click={toggleNewsFilter}>
          <img
            src={isNewsFilter ? 'icons/news-active.png' : 'icons/news.png'} />
        </div> -->
        <div class="icon" on:click={toggleIsFollowedFilter}>
          <img
            src={isFollowedFilter ? 'icons/following.png' : 'icons/follow.png'} />
        </div>
        <div class="icon" on:click={toggleIsGrantedFilter}>
          <img
            src={isGrantedFilter ? 'icons/isgranted.png' : 'icons/granted.png'} />
        </div>
      {/if}
      <KeywordFilter bind:keywordFilter />
    </div>
    <div
      class="categories-filter-frame {!isEventsSidepaneOn ? 'full-width' : ''}">
      <CategoriesFilter {categoriesInDataset} bind:categoriesFilter />
    </div>
  </div>
  <div class="dsa-filter-frame">
    <div class="dsa-filter-action-buttons">
      <div
        class="button"
        on:click={toggleVacancyFilter}
        title="Toggle vacancy filter">
        <img src="icons/hiring{isVacancyFilter ? '--active' : ''}.png" alt="" />
      </div>
      <div
        class="button"
        on:click={toggleDsaFilterActive}
        title="Toggle program filter active">
        <img src="/icons/switch_{dsaFilterActive ? 'on' : 'off'}.png" />
      </div>
      <div
        class="button {!dsaFilterActive ? 'button--inactive' : ''}"
        on:click={requestToggleDsaFilterShown}
        title="Open program picker">
        <img src="icons/equilizer{dsaFilterActive ? '--active' : ''}.png" alt="" />
      </div>
    </div>
    <div
      class="dsa-filter-carousel {!dsaFilterActive ? 'dsa-filter-carousel--inactive' : ''}">
      {#if dsaFilterActive && dsaFilter.length > 0}
        {#each dsaFilter as dsa}
          {#if isEventsSidepaneOn}
            <div
              class="dsa"
              class:dsa--m={dsa.includes('-m-')}
              class:dsa--w={dsa.includes('-w-')}
              class:dsa--x={dsa.includes('-x-')}
              class:dsa--hovered={dsa === dsaHovered}
              class:dsa--selected={dsaSelected.includes(dsa)}
              on:mouseenter={() => handleDsaHovered(dsa)}
              on:mouseleave={handleDsaUnhovered}
              on:click={() => toggleDsaSelected(dsa)}>
              {dsa
                .toUpperCase()
                .replace('-M', '')
                .replace('-W', '')
                .replace('-X', '')}
            </div>
          {:else}
            <div
              class="dsa"
              class:dsa--m={dsa.includes('-m-')}
              class:dsa--w={dsa.includes('-w-')}
              class:dsa--x={dsa.includes('-x-')}
              class:dsa--hovered={dsa === dsaHovered}
              class:dsa--selected={dsaSelected.includes(dsa)}
              on:click={() => toggleDsaSelected(dsa)}>
              {dsa
                .toUpperCase()
                .replace('-M', '')
                .replace('-W', '')
                .replace('-X', '')}
            </div>
          {/if}
        {/each}
      {:else}
        <div class="text">Filter by disciplines in program</div>
      {/if}
    </div>
  </div>
  <!-- <div class="brand-filters-frame">
    <BrandsFilter bind:highlightedBrand bind:brandsFilter />
  </div> -->
</div>
