<script>
  import YearlyCalendar from "../../ui/YearlyCalendar/index.svelte";
  import DateBrowser from "../../ui/DateBrowser/index.svelte";

  export let isEventsSidepaneOn;
  export let eventsByDate;
  export let dsaHoveredEventsByDate;
  export let listedEventsByDate;
  export let highlightedDateRange;
  export let shortlistedEventsByDate;
  export let selectedSeason;
  export let dateRangeFilter;
  export let hoveredDate;
</script>

<style>
  /* .frame {
    margin: 0 auto;
    max-width: calc(var(--calendar-width) + 40px);
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  } */
  .frame--no-sidepane {
    background-color: white;
  }
  .frame--sidepane-on {
    background-color: rgba(255, 255, 255, 0.7);
  }
</style>

<div
  class:frame--no-sidepane={!isEventsSidepaneOn}
  class:frame--sidepane-on={isEventsSidepaneOn}>
  {#if isEventsSidepaneOn !== undefined}
    {#if isEventsSidepaneOn}
      <YearlyCalendar
        itemsByDate={eventsByDate}
        dsaHoveredItemsByDate={dsaHoveredEventsByDate}
        listedItemsByDate={listedEventsByDate}
        shortlistedItemsByDate={shortlistedEventsByDate}
        {highlightedDateRange}
        bind:year={selectedSeason}
        bind:selectedDateRange={dateRangeFilter}
        bind:hoveredDate />
    {:else}
      <DateBrowser
        bind:year={selectedSeason}
        bind:selectedDateRange={dateRangeFilter} />
    {/if}
  {/if}
</div>
