<script>
  import { toDate, getDayOfYear, format, getWeekendInNWeeks } from "./index";

  /* calendar data */
  // export let itemsByDate;
  // export let listedItemsByDate;
  // export let highlightedDateRange;
  let today = new Date();
  export let year = today.getFullYear();
  export let selectedDateRange = { [year]: {} };
  console.log(year);
  let dateRange = {
    startDate: getWeekendInNWeeks(today, -1),
    endDate: getWeekendInNWeeks(today, 2),
  };

  const startDateChanged = () => {
    if (dateRange.startDate > dateRange.endDate)
      dateRange.endDate = dateRange.startDate;
    if (dateRange.startDate.substring(0, 4) !== year.toString()) {
      year = dateRange.startDate.substring(0, 4);
      dateRange.endDate = dateRange.startDate;
    }
  };
  const endDateChanged = () => {
    if (dateRange.endDate < dateRange.startDate)
      dateRange.startDate = dateRange.endDate;
    if (dateRange.endDate.substring(0, 4) !== year.toString()) {
      year = dateRange.endDate.substring(0, 4);
      dateRange.startDate = dateRange.endDate;
    }
  };

  $: if (selectedDateRange[year])
    selectedDateRange[year].start = getDayOfYear(new Date(dateRange.startDate));
  $: if (selectedDateRange[year])
    selectedDateRange[year].end = getDayOfYear(new Date(dateRange.endDate));
</script>

<div class="datepickers-frame">
  <div class="datepicker" title="start date">
    <img src="icons/startdate.png" />
    <input
      class="date"
      type="date"
      min="2020-01-01"
      max="2025-12-31"
      bind:value={dateRange.startDate}
      on:input={startDateChanged}
    />
  </div>
  <div class="datepicker" title="end date">
    <img src="icons/enddate.png" />
    <input
      class="date"
      type="date"
      min="2020-01-01"
      max="2025-12-31"
      bind:value={dateRange.endDate}
      on:input={endDateChanged}
    />
  </div>
  <!-- <div class="toggleFilters">
      <img src="icons/filter.png" />
    </div> -->
</div>

<style>
  .datepickers-frame {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    flex-wrap: wrap;
  }
  .toggleFilters {
    width: 7%;
    height: 36px;
    background-color: #ecf0f1;
    border-radius: 3px;
    display: flex;
  }
  .datepicker {
    width: 49%;
    height: 36px;
    background-color: #ecf0f1;
    border-radius: 3px;
    display: flex;
  }
  img {
    /* flex: none; */
    align-self: center;
    height: 25px;
    padding: 0 2vw 0 1vw;
  }
  .date {
    flex: 1;
    font-size: 12px;
    text-align: center;
    background-color: #ecf0f1;
  }
</style>
