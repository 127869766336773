<script>
  import { onMount } from "svelte";

  import Modal from "../../ui/Modal.svelte";
  import EventDetails from "../EventDetails/index.svelte";
  // import EventEditor from "../EventEditor/index.svelte";
  import DsaFilter from "./DsaFilter.svelte";
  import KeywordAndProgramFilter from "./KeywordAndProgramFilter.svelte";
  import DateRangeFilter from "./DateRangeFilter.svelte";
  import GeomapFilter from "./GeomapFilter.svelte";
  import EventsList from "./EventsList.svelte";

  import {
    fetchEventsFromDB,
    toggleFollowEventInDB,
    registerActivity,
    extractProgramFiltersFromEvents,
    filterEventsByIsFollowedFilter,
    filterEventsByKeywordFilter,
    filterEventsByDSAFilter,
    filterEventsByDSAHovered,
    filterEventsByDSASelected,
    filterEventsByHighlightedBrand,
    filterEventsByCategoriesFilter,
    countEventsByDate,
    filterEventsByDateRangeFilter,
    filterEventsByHoveredDate,
    filterEventsByGeoFilter,
    getHighlightedDateRange,
    adaptDisplay,
  } from "./index";

  import { user, viewportWH } from "../../stores";

  let selectedSeason;
  let hoveredDate;

  let mapWidth;
  let userHomeLocation = {};
  let userCurrentLocation = {};

  let keywordFilter = "";
  let brandsFilter = [];
  let isVacancyFilter = false;
  let isNewsFilter = false;
  let isFollowedFilter = false;
  let isGrantedFilter = false;
  let dsaFilter = [];
  let categoriesFilter = [];
  let dateRangeFilter = {};
  let geoFilter = {};
  let highlightedDateRange = {};

  let unfilteredEvents;
  let eventsByDate = {};
  // let dsaFilteredEvents = [];
  // let categoriesFilteredEvents = [];
  let keywordFilteredEvents = [];
  // let isFollowedFilteredEvents = [];
  let dateRangeFilteredEvents = [];
  // let geoFilteredEvents = [];
  let shortlistedEvents = [];
  // let filteredEvents = [];
  // let listedEvents = [];

  let dsaFilterShown = false;
  let dsaFilterActive = false;
  let dsaFilterModalW;
  let dsaHovered;
  let dsaSelected;

  let highlightedBrand;

  let categoriesInUnfilteredEvents = [];
  let competitionsInUnfilteredEvents = [];

  let highlightedEvent;
  let selectedEvent;

  let eventDetailsShown = false;
  let eventDetailsModalW = 1000;

  // let idb;
  let mounted = false;

  let eventsListWidth;
  let totalEventsListColumns;
  let isEventsSidepaneOn;

  const initializeUserBrowsingSettings = (season, idUser) => {
    if (localStorage.getItem(idUser)) {
      let browsing = JSON.parse(localStorage.getItem(idUser));
      keywordFilter = browsing.keywordFilter || keywordFilter;
      dsaFilter = browsing.dsaFilter || dsaFilter;
      categoriesFilter = browsing.categoriesFilter || categoriesFilter;
      if (season && browsing.dateRanges && browsing.dateRanges[season])
        dateRangeFilter[season] = {
          start: browsing.dateRanges[season].start,
          end: browsing.dateRanges[season].end,
        };
      geoFilter = browsing.geoFilter || {};
      isGrantedFilter = browsing.isGrantedFilter || false;
    }
  };
  const refreshUnfilteredEvents = async (
    season,
    isGrantedFilter,
    datasetRefresh
  ) => {
    if (!season) return;
    const eventsType = isGrantedFilter ? "granted" : "public";
    categoriesInUnfilteredEvents = [];
    unfilteredEvents = [];
    let accessFrom;
    let {
      whenLastUpdate,
      list: fetchedEvents,
      categoriesInDataset,
      ...login
    } = await fetchEventsFromDB({
      user: $user,
      eventsType,
      season,
      accessFrom,
    });
    if (!fetchedEvents) {
      // if login data fetched
      registerActivity(
        {
          type: "user login using cookie",
        },
        $user.token
      );
      $user = login;
      await refreshUnfilteredEvents(season, isGrantedFilter);
      return;
    }
    // if event data fetched
    unfilteredEvents = fetchedEvents;
    categoriesInUnfilteredEvents = categoriesInDataset;
    registerActivity(
      {
        type: "events dataset delivered",
        dataset:
          $user.events[season] && $user.events[season][eventsType]
            ? "update"
            : "full",
        eventsDelivered: fetchedEvents.length,
      },
      $user.token
    );
  };

  // const handleEventSaved = async e => {
  //   selectedEvent = e.detail;
  //   await refreshUnfilteredEvents(true);
  // };
  const handleEventDeleted = async () => {
    await refreshUnfilteredEvents(selectedSeason, true);
    eventDetailsShown = false;
  };
  const toggleEventDetailsShown = async () => {
    const type = eventDetailsShown ? "event dismissed" : "event selected";
    const eventId = selectedEvent.properties.id;
    registerActivity({ type, eventId }, $user.token);
    switch (type) {
      case "event selected":
        // TODO probaj učitati friške podatke o priredbi prije nego ju prikažeš
        break;
      case "event dismissed":
        // probaj učitati podatke o svim priredbama izmijenjenim u međuvremenu
        // - za sada ovo radi samo za slučaj admin izmjene podataka,
        // osvježavanje za obične korisnike ćeš riješiti kroz websocket
        if (isGrantedFilter)
          await refreshUnfilteredEvents(selectedSeason, isGrantedFilter, true);
        break;
    }
    eventDetailsShown = !eventDetailsShown;
  };
  const toggleEventEditorShown = () => {
    eventEditorShown = !eventEditorShown;
  };
  const toggleDsaFilterShown = () => {
    dsaFilterShown = !dsaFilterShown;
  };
  const selectEvent = (e) => {
    selectedEvent = e.detail;
    toggleEventDetailsShown();
  };
  const toggleFollowEvent = (e) => {
    const eventToToggle = e.detail;
    unfilteredEvents = unfilteredEvents.map((event) => {
      if (event.properties.id === eventToToggle.properties.id)
        event.properties.isFollowed = !event.properties.isFollowed;
      if (!event.properties.isFollowed) {
        highlightedEvent = null;
      }
      return event;
    });
    toggleFollowEventInDB($user, eventToToggle);
  };
  const highlightBrand = (e) => {
    highlightedBrand = e.detail;
  };

  $: if (selectedSeason) {
    // eventsByDate = listedEventsByDate = {};
    keywordFilteredEvents = [];
  }
  $: if (selectedSeason) {
    refreshUnfilteredEvents(selectedSeason, isGrantedFilter);
  }
  $: isFollowedFilteredEvents = isFollowedFilter
    ? filterEventsByIsFollowedFilter(unfilteredEvents)
    : unfilteredEvents;
  const filterEventsByVacancyFilter = (events) => {
    const origEvents = events
    const outEvents = events.filter(event => event.properties.program.vacancyAlerts)
    return outEvents
    }
  $: isVacancyFilteredEvents = isVacancyFilter 
    ? filterEventsByVacancyFilter(unfilteredEvents) 
    : unfilteredEvents
  $: dsaFilteredEvents = dsaFilterActive
    ? filterEventsByDSAFilter(isVacancyFilteredEvents, dsaFilter)
    : isVacancyFilteredEvents;
  $: dsaSelectedEvents = dsaFilterActive
    ? filterEventsByDSASelected(dsaFilteredEvents, dsaSelected)
    : dsaFilteredEvents;
  $: dsaHoveredEvents = filterEventsByDSAHovered(dsaSelectedEvents, dsaHovered);
  // ****
  $: dsaHoveredEventsByDate = {};
  // $: dsaHoveredEventsByDate = isEventsSidepaneOn
  //   ? countEventsByDate(dsaHoveredEvents)
  //   : {};
  // ****

  $: categoriesFilteredEvents = filterEventsByCategoriesFilter(
    dsaSelectedEvents,
    categoriesFilter
  );
  $: keywordFilteredEvents =
    filterEventsByKeywordFilter(categoriesFilteredEvents, keywordFilter) ||
    categoriesFilteredEvents;

  $: eventsByDate = isEventsSidepaneOn
    ? countEventsByDate(keywordFilteredEvents)
    : {};
  $: geoFilteredEvents = filterEventsByGeoFilter(
    keywordFilteredEvents,
    geoFilter
  );
  $: listedEventsByDate = isEventsSidepaneOn
    ? countEventsByDate(geoFilteredEvents)
    : {};
  $: if (selectedSeason)
    dateRangeFilteredEvents =
      !isEventsSidepaneOn && keywordFilter != ""
        ? keywordFilteredEvents
        : selectedSeason && dateRangeFilter
        ? filterEventsByDateRangeFilter(
            keywordFilteredEvents,
            dateRangeFilter[selectedSeason]
          )
        : [];
  $: listedEvents =
    !isEventsSidepaneOn && keywordFilter != ""
      ? keywordFilteredEvents
      : filterEventsByGeoFilter(dateRangeFilteredEvents, geoFilter);
  $: shortlistedEvents = filterEventsByHoveredDate(listedEvents, hoveredDate);
  $: shortlistedEvents = filterEventsByDSAHovered(listedEvents, dsaHovered);
  $: shortlistedEvents = filterEventsByHighlightedBrand(
    listedEvents,
    highlightedBrand
  );
  $: shortlistedEventsByDate = isEventsSidepaneOn
    ? countEventsByDate(shortlistedEvents)
    : {};

  $: highlightedDateRange = getHighlightedDateRange(highlightedEvent);

  onMount(() => {
    mounted = true;
  });

  $: if (mounted) {
    ({ totalEventsListColumns, isEventsSidepaneOn, mapWidth, eventsListWidth } =
      adaptDisplay($viewportWH.w));
  }

  $: if (mounted && selectedSeason)
    initializeUserBrowsingSettings(selectedSeason, $user.id);
  $: if (mounted && selectedSeason) {
    localStorage.setItem(
      $user.id,
      JSON.stringify({
        selectedSeason,
        keywordFilter,
        dsaFilter,
        categoriesFilter,
        dateRanges: {
          ...(localStorage.getItem($user.id)
            ? JSON.parse(localStorage.getItem($user.id)).dateRanges
            : {}),
          [selectedSeason]: {
            start:
              dateRangeFilter[selectedSeason] &&
              dateRangeFilter[selectedSeason].start,
            end:
              dateRangeFilter[selectedSeason] &&
              dateRangeFilter[selectedSeason].end,
          },
        },
        geoFilter,
        isGrantedFilter,
      })
    );
  }
</script>

{#if eventDetailsShown}
  <div class="event-details">
    <Modal
      on:hideModalRequested={toggleEventDetailsShown}
      modalWidth={eventDetailsModalW}
    >
      <EventDetails
        event={selectedEvent}
        userLocation={userCurrentLocation}
        {isGrantedFilter}
        bind:dsaFilter
        on:editEvent={toggleEventEditorShown}
        on:eventDeleted={handleEventDeleted}
      />
    </Modal>
  </div>
{/if}

{#if dsaFilterShown}
  <div class="dsa-filter">
    <Modal
      modalWidth={dsaFilterModalW}
      on:hideModalRequested={toggleDsaFilterShown}
    >
      <DsaFilter bind:dsaFilter bind:dsaFilterModalW />
    </Modal>
  </div>
{/if}

{#if mounted}
  <div class="frame" style="width: {mapWidth}px">
    <div class="geomap{isEventsSidepaneOn ? '' : '-on-narrow-screen'}">
      <GeomapFilter
        on:selectEventRequested={selectEvent}
        viewportWH={$viewportWH}
        {userHomeLocation}
        {userCurrentLocation}
        events={dateRangeFilteredEvents}
        {shortlistedEvents}
        bind:highlightedEvent
        bind:geoFilter
      />
    </div>
    <div class="lower-section{isEventsSidepaneOn ? '' : '-on-narrow-screen'}">
      <div class="filters-and-events">
        <div class="filters-frame">
          <div class="program-filters">
            <KeywordAndProgramFilter
              {isEventsSidepaneOn}
              categoriesInDataset={categoriesInUnfilteredEvents}
              {dsaFilter}
              bind:categoriesFilter
              bind:brandsFilter
              bind:highlightedBrand
              bind:keywordFilter
              bind:isVacancyFilter
              bind:isNewsFilter
              bind:isFollowedFilter
              bind:isGrantedFilter
              bind:dsaFilterActive
              bind:dsaHovered
              bind:dsaSelected
              on:toggleDsaFilterShownRequested={toggleDsaFilterShown}
            />
          </div>
          <div class="date-range-filter">
            <DateRangeFilter
              {isEventsSidepaneOn}
              {eventsByDate}
              {dsaHoveredEventsByDate}
              {listedEventsByDate}
              {shortlistedEventsByDate}
              {highlightedDateRange}
              bind:selectedSeason
              bind:dateRangeFilter
              bind:hoveredDate
            />
          </div>
        </div>
        <div
          class:events-list-sidepane={isEventsSidepaneOn}
          style="width: {eventsListWidth}px"
        >
          <EventsList
            on:selectEventRequested={selectEvent}
            on:toggleFollowEventRequested={toggleFollowEvent}
            on:highlightBrandRequested={highlightBrand}
            {isEventsSidepaneOn}
            {totalEventsListColumns}
            events={listedEvents}
            {shortlistedEvents}
            bind:highlightedEvent
          />
        </div>
      </div>
    </div>
  </div>
{/if}

<style>
  .event-details {
    position: relative;
    z-index: 3;
  }
  .dsa-filter {
    position: relative;
    z-index: 3;
  }
  .frame {
    position: relative;
    z-index: 1; /**/
    height: calc(100vh - var(--navbar-ht));
    overflow: hidden;
  }
  .frame .geomap {
    position: relative;
    z-index: 1;
    height: calc(100vh - var(--navbar-ht) - 390px);
    min-height: 300px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  .frame .geomap-on-narrow-screen {
    position: relative;
    z-index: 2;
    height: 35vh;
    min-height: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  .frame .lower-section {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 2;
    bottom: 10px;
  }
  .frame .lower-section-on-narrow-screen {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    z-index: 1;
    top: calc(35vh + 10px);
    bottom: 0;

    /* z-index: 3;
    border: 3px solid red; */
  }
  .events-list-sidepane {
    position: fixed;
    top: var(--navbar-ht);
    bottom: 0;
    right: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  .frame .filters-and-events {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto; /* ova ti osigurava da imaš skrolanje ali da nemaš scrollbar */
    /* width: 100%; */ /* ovoga NE SMIJEŠ imati, jer ti razbija skrolanje bez scrollbara*/
    top: 0;
    overflow-y: auto;
  }
  .frame .filters-and-events .filters-frame {
    position: sticky;
    z-index: 3;
    top: -160px;
    margin: 10px;
    padding: 10px;
    max-width: calc(var(--calendar-width) + 40px);
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
</style>
