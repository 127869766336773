<script>
  import { createEventDispatcher } from "svelte";
  import { trimLongTitle } from "./EventCard";
  import { getEventCategoriesFromDB } from "../EventEditor/index";

  const dispatch = createEventDispatcher();

  import { user } from "../../stores";
  export let event = {};
  export let isHighlighted = false;
  // let isUnpublished = !event.properties.isPublished;
  // let isFollowed = event.properties.isFollowed;
  let categoriesList = getEventCategoriesFromDB().reverse();
  let isBrandHighlighted = false;

  const requestHighlightEvent = () => {
    dispatch("highlightEventRequested", event);
  };
  const requestUnhighlightEvent = () => {
    dispatch("highlightEventRequested");
  };
  const requestHighlightBrand = () => {
    dispatch("highlightBrandRequested", event.properties.brand);
    isBrandHighlighted = true;
  };
  const requestUnhighlightBrand = () => {
    dispatch("highlightBrandRequested");
    isBrandHighlighted = false;
  };
  const requestSelectEvent = () => {
    dispatch("selectEventRequested", event);
  };
  const toggleRequestFollowEvent = () => {
    dispatch("toggleFollowEventRequested", event);
  };

  $: isUnpublished = !event.properties.isPublished;
  $: isFollowed = event.properties.isFollowed;
</script>

<div
  class="event-card"
  class:isHighlighted
  on:mouseenter={requestHighlightEvent}
  on:mouseleave={requestUnhighlightEvent}
>
  <div
    class="body"
    class:isUnpublished
    on:click|stopPropagation={requestSelectEvent}
  >
    {#if event.properties.brand === "eapcircuit"}
      <img class="brand-bg" src="brands/eapcircuit.png" />
    {/if}
    {#if event.properties.brand === "goldenfy"}
      <img class="brand-bg" src="brands/goldenfly-inactive.png" />
    {/if}
    <div class="event-info">
      <div class="flag-place-date">
        <div class="flag">
          <img
            src="flags/svg/{event.properties.venue.country}.svg"
            alt={event.properties.venue.country}
          />
        </div>
        <span class="place-date">
          <div class="place">
            {trimLongTitle(
              event.properties.venue.place[event.properties.venue.place.pp],
              24
            )}
          </div>
          <div class="date">{event.properties.dateForDisplay}</div>
        </span>
      </div>
      <div class="event-title">
        <div class="text">
          {trimLongTitle(event.properties.title[event.properties.title.pp], 57)}
        </div>
      </div>
    </div>
    <div class="categories">
      {#each event.properties.program.categories as category}
        <div class="EventCard__category">{category.toUpperCase()}</div>
      {/each}
    </div>
  </div>
  <div class="footer" class:isUnpublished>
    <div class="brands">
      {#if event.properties.program.vacancyAlerts}
        <div class="warrantor-icon">
          <img
              src="icons/hiring--active.png"
              title="Vacancy Alert"
              alt="Vacancy Alert"
            />
        </div>
      {/if}
      {#if event.properties.eventWarrantor && event.properties.eventWarrantor.id !== "-"}
        <div class="warrantor-icon">
          <a href={event.properties.eventWarrantor.www} target="_blank">
            <img
              src="warrantors/{event.properties.eventWarrantor.id}.png"
              title="Event info maintained by {event.properties.eventWarrantor
                .eng}"
              alt={event.properties.eventWarrantor.eng}
            /></a
          >
        </div>
      {/if}
      {#each event.properties.eventBrands as brand}
        {#if event.properties.brand === "eapcircuit"}
          <div
            class="brand-icon"
            on:mouseenter={requestHighlightBrand}
            on:mouseleave={requestUnhighlightBrand}
          >
            <a href="http://eap-circuit.org" target="_blank">
              <img
                src="brands/{isBrandHighlighted
                  ? 'eap-active'
                  : 'eap-inactive'}.svg"
                title="EAP Circuit"
                alt="EAP Circuit"
              /></a
            >
          </div>
        {/if}
        {#if event.properties.brand === "goldenfly"}
          <div
            class="brand-icon"
            on:mouseenter={requestHighlightBrand}
            on:mouseleave={requestUnhighlightBrand}
          >
            <a href="https://www.goldenflyseries.com/" target="_blank">
              <img
                src="brands/{isBrandHighlighted
                  ? 'goldenfly-active'
                  : 'goldenfly-inactive'}.png"
                title="Golden Fly Series"
                alt="Golden Fly Series"
              /></a
            >
          </div>
        {/if}
      {/each}
    </div>
    <div class="actions">
      <!-- invitation / start lists / results -->
      {#if event.properties.links.results}
        <div class="action-button">
          <a href={event.properties.links.results} target="_blank">
            <img src="icons/results.svg" alt="Results" />
          </a>
        </div>
      {:else if event.properties.links.startlist && event.properties.dateRange.daysToStart >= 0}
        <div class="action-button">
          <a href={event.properties.links.startlist} target="_blank">
            <img src="icons/list.png" alt="Start lists" />
          </a>
        </div>
      {:else if event.properties.links.invitation && event.properties.dateRange.daysToStart >= 0}
        <div class="action-button">
          <a href={event.properties.links.invitation} target="_blank">
            <img src="icons/info.png" alt="Invitation/Program" />
          </a>
        </div>
      {/if}

      <!-- real time video streaming -->
      {#if event.properties.links.videochannel && event.properties.dateRange.daysToStart < 2 && event.properties.dateRange.daysToEnd > -2}
        <div class="action-button">
          <a href={event.properties.links.stream} target="_blank">
            <img src="icons/livestream.png" alt="Live Stream" />
          </a>
        </div>
      {/if}
      <!-- post-competition recordings - videos/photos -->
      {#if event.properties.links.video}
        <div class="action-button">
          <a href={event.properties.links.video} target="_blank">
            <img src="icons/video.png" alt="Video Channel" />
          </a>
        </div>
      {/if}
      {#if event.properties.links.gallery}
        <div class="action-button">
          <a href={event.properties.links.gallery} target="_blank">
            <img src="icons/gallery.png" alt="Gallery" />
          </a>
        </div>
      {/if}
      <!-- <div class="action-button" on:click|stopPropagation={requestSelectEvent}>
        <img src="icons/see-more.svg" />
      </div> -->
      {#if $user.role !== "guest"}
        <div class="action-button" on:click={toggleRequestFollowEvent}>
          <img
            src={isFollowed ? "icons/following.png" : "icons/follow.png"}
            height="22px"
          />
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  :root {
    --flag-width: 45px;
    --flag-height: 32px;
  }
  .event-card {
    margin: 10px;
    background-color: whitesmoke;
    border-radius: 3px;
    box-shadow: 0 0 5px 5px var(--color-backg-mid);
    display: flex;
    flex-direction: column;
  }
  .isHighlighted {
    box-shadow: 0 0 5px 5px rgba(221, 11, 11, 0.5);
  }
  .event-card .isDraft {
    background-color: lightgoldenrodyellow;
    color: navy;
  }
  .event-card .isCanceled {
    background-color: darkslategrey;
    color: white;
  }
  .event-card .isUnpublished {
    background-color: darkslategrey;
    color: white;
  }
  .event-card .body {
    position: relative;
    width: 100%;
    padding: 10px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
  .event-card .body .brand-bg {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%; /*
    height: auto; */
    opacity: 0.15;
  }
  .event-card .body .event-info {
    width: calc(100% - 28px);
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    z-index: 2;
  }
  .event-card .body .event-info .flag-place-date {
    height: var(--flag-height);
    display: flex;
  }
  .event-card .body .event-info .flag-place-date .flag {
    height: var(--flag-height);
    width: var(--flag-width);
    overflow: hidden;
    box-shadow: 0 0 5px 5px var(--color-backg-mid);
  }
  .event-card .body .event-info .flag-place-date .flag img {
    height: var(--flag-height);
    width: var(--flag-width);
    object-fit: cover;
  }
  .event-card .body .event-info .place-date {
    flex: 1 1 auto;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
  }
  .event-card .body .event-info .place-date .place {
    font-size: 15px;
    font-weight: bolder;
  }
  .event-card .body .event-info .place-date .date {
  }
  .event-card .body .event-info .event-title {
    height: 55px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .event-card .body .event-info .event-title .text {
    font-size: 16px;
    text-align: center;
    /* text-overflow: ellipsis;
    overflow: hidden;
    height: 2em;
    line-height: 1em; */
  }
  .event-card .body .categories {
    /* border: 1px solid black; */
    width: 28px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 8px;
    z-index: 2;
  }
  .EventCard__category {
    height: 26px;
    width: 26px;
    background-image: url(/kategorije/16x16px/orange/pin.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    /* box-shadow: 0 0 5px 5px var(--color-backg-mid); */

    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 14px;
    font-style: italic;
    padding-top: 4px;
  }
  /* .event-card .body .categories .category-icon {
    height: 28px;
    width: 26px;
  }
  .event-card .body .categories .category img {
    width: 100%;
    object-fit: cover;
  } */
  .event-card .footer {
    height: 40px;
    padding: 0 10px;
    background-color: var(--color-foreg-shadow);
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .event-card .footer .brands {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-start;
    align-items: center;
  }
  .event-card .footer .brands .warrantor-icon {
    height: 30px;
    width: 30px;
    margin: 0 3px 0 0;
    cursor: pointer;
  }
  .event-card .footer .brands .warrantor-icon img {
    width: 100%;
    object-fit: contain;
    border-radius: 3px;
  }
  .event-card .footer .brands .brand-icon {
    height: 30px;
    width: 30px;
    margin: 0 3px 0 0;
    cursor: pointer;
    /* border: 1px solid black; */
  }
  .event-card .footer .brands .brand-icon img {
    width: 100%;
    object-fit: contain;
  }
  .event-card .footer .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .event-card .footer .actions .action-button {
    height: 30px;
    width: 30px;
    padding: 3px;
    margin: 0 0 0 5px;
    border-radius: 3px;
    background-color: var(--color-action-main);
    cursor: pointer;
  }
  .event-card .footer .actions .action-button:hover {
    background-color: var(--color-action-hover);
  }
  .event-card .footer .actions .action-button img {
    width: 100%;
    object-fit: contain;
  }
</style>
