<script>
  import { getDayName, checkIsSlotWeekend } from "./DaysOfWeek";

  export let monthDaysSlots;
  export let firstDayOfWeek;
</script>

<style>
  tr {
    background-color: grey;
    line-height: 16px;
  }
  th {
    width: 14px;
    font-size: 10px;
    text-align: center;
    color: white;
    background-color: grey;
    padding: 0px;
  }
  .isWeekend {
    color: black;
    background-color: lightgray;
  }
  .null {
    background-color: white;
  }
  .separator {
    width: 1px;
    background-color: white;
  }
</style>

<tr>
  <th class="null" />
  {#each monthDaysSlots as slot}
    {#if slot.weekDay === firstDayOfWeek}
      <th class:separator={true} />
    {/if}
    <!-- <th key={`weekday-${slot.id}`} class:bolder={slot.weekDayNo === 0}>
      {getDayName(day)}
    </th> -->
    <th class:isWeekend={checkIsSlotWeekend(slot)}>
      {getDayName(slot)}
    </th>
  {/each}
</tr>
