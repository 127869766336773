<script>
  import {
    checkIfDayIsWithinDateRange,
    checkIfDayisToday,
    getNoOfItemsForDay,
  } from "./Day";

  export let day;
  export let today;
  export let itemsByDate;
  export let dsaHoveredItemsByDate;
  export let listedItemsByDate;
  export let shortlistedItemsByDate;
  export let isEvenYear;
  export let highlightedDateRange;
  export let selectedYearDateRange;
  export let hoveredDate;
  let isHovered = false;

  const updateSelectedDateRange = () => {
    selectedYearDateRange.start === selectedYearDateRange.end
      ? day.yearDay >= selectedYearDateRange.start
        ? (selectedYearDateRange.end = day.yearDay)
        : (selectedYearDateRange.start = day.yearDay)
      : (selectedYearDateRange.start = selectedYearDateRange.end = day.yearDay);
  };
  const toggleDayIsHovered = () => {
    isHovered = !isHovered;
    hoveredDate = isHovered ? day.yearDay : undefined;
  };

  $: isWithinSelectedDateRange = checkIfDayIsWithinDateRange(
    day,
    selectedYearDateRange
  );
  $: isToday = day.month.year ? checkIfDayisToday(day, today) : false;
  //****
  $: isDSAHighlighted = getNoOfItemsForDay(day, dsaHoveredItemsByDate) > 0;
  //****
  $: isHighlighted =
    isHovered || checkIfDayIsWithinDateRange(day, highlightedDateRange);
  // || getNoOfItemsForDay(day, shortlistedItemsByDate) > 0;
  $: noOfEvents = getNoOfItemsForDay(day, itemsByDate);
  $: noOfListedEvents = getNoOfItemsForDay(day, listedItemsByDate);
</script>

<td
  on:click={updateSelectedDateRange}
  on:mouseenter={toggleDayIsHovered}
  on:mouseleave={toggleDayIsHovered}
  class:isEvenYear
  class:isHighlighted
  class:isDSAHighlighted={!isHighlighted && isDSAHighlighted}
  class:isWithinSelectedDateRange
  class:hasEvents={!isHighlighted &&
    !isDSAHighlighted &&
    !isToday &&
    noOfEvents > 0}
  class:hasEventsAndIsWithinSelectedDateRange={!isHighlighted &&
    !isDSAHighlighted &&
    !isToday &&
    noOfEvents > 0 &&
    isWithinSelectedDateRange}
  class:hasListedEvents={!isHighlighted &&
    !isDSAHighlighted &&
    !isToday &&
    noOfListedEvents > 0}
  class:hasListedEventsAndIsWithinSelectedDateRange={!isHighlighted &&
    !isDSAHighlighted &&
    !isToday &&
    noOfListedEvents > 0 &&
    isWithinSelectedDateRange}
  class={isToday ? (isEvenYear ? "isTodayInEvenYear" : "isToday") : ""}
>
  {day.monthDay}
  {#if isHovered && noOfEvents > 0}
    <span class="tooltiptext">
      {`${day.monthDay}/${day.month.monthNo}/${day.month.year}`}<br />
      {#if isWithinSelectedDateRange}{noOfListedEvents}/{/if}{noOfEvents}
      event{noOfEvents > 1 ? "s" : ""}</span
    >
  {/if}
</td>

<style>
  td {
    position: relative;
    width: 14px;
    font-size: 10px;
    text-align: center;
    height: 12px;
    /* color: black; */
    color: darkslategrey;
    border: 1px dotted lightgray;
    /* padding: 0px 1px; */
    cursor: pointer;
    padding: 0px;
  }

  .isEvenYear {
    color: darkslategrey;
  }

  .isWithinSelectedDateRange {
    background: rgba(128, 128, 128, 0.616);
    /* border: 1px solid darkslategrey; */
    color: whitesmoke;
    font-weight: bold;
  }

  .isToday {
    background: slategrey;
    color: whitesmoke;
    /* font-weight: bold; */
  }

  .isTodayInEvenYear {
    background: slategrey;
    color: whitesmoke;
    /* font-weight: bolder; */
  }

  .isHighlighted {
    background: #e22c3b;
    color: white;
    /* font-weight: bold; */
  }

  .isDSAHighlighted {
    background: #e22c3b63;
    color: white;
  }

  .hasEvents {
    background: #545ad260;
    /* color: darkslategrey; */
    /* font-weight: bold; */
    /* border: 1px solid red; */
  }

  .hasEventsAndIsWithinSelectedDateRange {
    background: #545bd2;
    color: white;
  }

  .hasListedEvents {
    background: #f5a0205d;
    /* color: black; */
    /* font-style: italic; */
    /* font-weight: bold; */
    /* border: 1px solid red; */
  }

  .hasListedEventsAndIsWithinSelectedDateRange {
    background: #f5a020;
    color: white;
  }

  .tooltiptext {
    width: 70px;
    background-color: darkslategrey;
    color: white;
    text-align: center;
    padding: 3px 0;
    border-radius: 6px;
    font-size: 12px;
    font-weight: normal;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    top: -35px;
    left: -65px;
    z-index: 1;
  }
</style>
