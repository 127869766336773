<script>
  import { makeMonthDays } from "./Month";

  import Day from "./Day.svelte";

  export let month;
  export let monthDaysSlots;
  export let firstDayOfWeek;
  export let today;
  export let itemsByDate;
  export let dsaHoveredItemsByDate;
  export let listedItemsByDate;
  export let shortlistedItemsByDate;
  export let isEvenYear;
  export let highlightedDateRange;
  export let selectedYearDateRange;
  export let hoveredDate;

  const selectMonth = () => {
    selectedYearDateRange.start = month.firstDayYearDay;
    selectedYearDateRange.end = month.lastDayYearDay;
  };

  $: monthDays = makeMonthDays(month, monthDaysSlots);
</script>

<style>
  tr {
    height: 16px;
  }
  td {
    width: 14px;
    font-size: 11px;
    text-align: end;
    padding: 0px;
  }
  .separator {
    width: 1px;
  }
  .monthName {
    color: darkslategrey;
    width: 50px;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
  }
  .isEvenYear {
    color: darkslategrey;
  }
  .bolder {
    font-weight: bolder;
  }
</style>

<tr>
  <td class="bolder monthName" class:isEvenYear on:click={selectMonth}>
    {month.monthYearTitle}
  </td>
  {#each monthDays as day}
    {#if day.weekDay === firstDayOfWeek}
      <td class:separator={true} />
    {/if}
    {#if day.monthDay}
      <Day
        {day}
        {today}
        {itemsByDate}
        {dsaHoveredItemsByDate}
        {listedItemsByDate}
        {shortlistedItemsByDate}
        {isEvenYear}
        {highlightedDateRange}
        bind:selectedYearDateRange
        bind:hoveredDate />
    {:else}
      <td />
    {/if}
  {/each}
</tr>
