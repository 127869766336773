<script>
  import CompetitionsPicker from "../../ui/CompetitionsPicker/index.svelte";

  export let dsaFilter = [];
  export const dsaFilterModalW = 1311;
</script>

<style>
  .frame {
    background-color: white;
  }
</style>

<div class="frame">
  <CompetitionsPicker bind:selectedDsaItems={dsaFilter} />
</div>
