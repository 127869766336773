<script>
  import { createEventDispatcher, onMount } from "svelte";

  const dispatch = createEventDispatcher();

  export let homeLocation;
  export let bounds;
  export let currentLocation;
  export let features;
  export let shortlistedFeatures;
  export let highlightedFeature = {};
  export let viewportWH;
  let map;
  let miniMap;
  let minimapW;
  let miniMapTile;
  let mounted = false;
  let featuresLayer;
  let miniMapFeaturesLayer;
  let shortlistedFeaturesLayer;
  let highlightedFeatureLayer;
  let externalHighlight = true;
  const initializeMap = () => {
    // attach map to div
    map = L.map("mapdiv", {
      // center,
      // zoom,
      scrollWheelZoom: true,
      wheelPxPerZoomLevel: 240,
      zoomControl: false,
      attributionControl: false,
			maxBoundsViscosity: 0,
			maxZoom: 16,
			minZoom: 4
      // gestureHandling: true
    });
    // Define the geographical bounds (latitude and longitude) to limit the map panning
		// and limit the map to this bounding box so that the map cannot be panned outside it
		const maxBounds = L.latLngBounds(
			L.latLng(-60.0, -190.0), // Southwest corner [latitude, longitude]
			L.latLng(70.0, 190.0) // Northeast corner [latitude, longitude]
		);
		map.setMaxBounds(maxBounds);
		// determine map center and zoom
    // determine map center and zoom
    let center;
    let zoom;
    if (bounds._southWest) {
      // use existing bounds, if set...
      let swCorner = L.latLng(bounds._southWest.lat, bounds._southWest.lng);
      let neCorner = L.latLng(bounds._northEast.lat, bounds._northEast.lng);
      bounds = L.latLngBounds([swCorner, neCorner]);
      center = bounds.getCenter();
      zoom = map.getBoundsZoom(bounds);
      // map.fitBounds(bounds)
    } else {
      // otherwise get bounds from current user location
      center =
        currentLocation &&
        currentLocation.geometry &&
        currentLocation.geometry.coordinates.length > 0
          ? [
              currentLocation.geometry.coordinates[1],
              currentLocation.geometry.coordinates[0],
            ]
          : [45, 15]; // geolokacija Zagreba,
      zoom = 6;
    }
    // redraw map on last position
    map.setView(center);
    map.setZoom(zoom);
    bounds = map.getBounds();
    // set map variables
    let mapURL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    var cartoUrl = "http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png";
    let attribution = `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`;
    // attach base tile layer to map
    let mapTile = L.tileLayer(mapURL, {
      attribution,
    });
    map.addLayer(mapTile);
    // add attrbution
    L.control.attribution({ position: "topleft" }).addTo(map);
    // handle view changes
    map.on("resize moveend zoomend", () => {
      bounds = map.getBounds();
    });
    // add minimap
    miniMapTile = L.tileLayer(mapURL, {
      minZoom: 0,
      maxZoom: 0,
    });
    miniMap = new L.Control.MiniMap(miniMapTile, {
      position: "topright",
      toggleDisplay: true,
      height: minimapW * (2 / 3),
      width: minimapW,
      zoomLevelFixed: 0,
      // centerFixed: [30, 10],
      aimingRectOptions: { color: "slategrey", weight: 2 },
      shadowRectOptions: {
        color: "#0000AA",
        weight: 1,
        opacity: 0,
        fillOpacity: 0,
      },
    });
    map.addControl(miniMap);
  };

  const getIcon = (feature) => {
    let iconUrl;
    switch (feature.properties.brand) {
      case "eapcircuit":
        iconUrl = "brands/eap-inactive.svg";
        break;
      case "goldenfly":
        iconUrl = "brands/goldenfly-inactive.png";
        break;
    }
    return iconUrl
      ? L.icon({
          iconUrl: iconUrl,
          iconSize: [22, 22], // size of the icon
          iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
          popupAnchor: [24, 0], // point from which the popup should open relative to the iconAnchor
          opacity: 1,
        })
      : L.divIcon({
          className: "markerIcon",
          html: feature.properties.category.toUpperCase(),
          iconSize: [24, 24], // size of the icon
          iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
          popupAnchor: [24, 0], // point from which the popup should open relative to the iconAnchor
          opacity: 1,
        });
  };
  const getHoveredIcon = (feature) => {
    let activeIconUrl;
    switch (feature.properties.brand) {
      case "eapcircuit":
        activeIconUrl = "brands/eap-active.svg";
        break;
      case "goldenfly":
        activeIconUrl = "brands/goldenfly-active.png";
        break;
    }
    return activeIconUrl
      ? L.icon({
          iconUrl: activeIconUrl,
          iconSize: [22, 22], // size of the icon
          iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
          popupAnchor: [24, 0], // point from which the popup should open relative to the iconAnchor
          opacity: 1,
        })
      : L.divIcon({
          className: "highlightedMarkerIcon",
          html: feature.properties.category.toUpperCase(),
          iconSize: [24, 24], // size of the icon
          iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
          popupAnchor: [24, 0], // point from which the popup should open relative to the iconAnchor
          opacity: 1,
        });
  };
  const getShortlistedIcon = (feature) => getHoveredIcon(feature);
  const getHighlightedIcon = (feature) => getHoveredIcon(feature);

  const handleHoverAndClick = (marker, feature) => {
    marker.on("mouseover", () => {
      externalHighlight = false;
      dispatch("featureHovered", feature);
      marker.setIcon(getHoveredIcon(feature));
    });
    marker.on("click", () => {
      dispatch("featureClicked", feature);
    });
    return marker;
  };
  const featureToLayer = (feature, latlng) => {
    const zIndexOffset = 600;
    let marker = L.marker(latlng);
    marker.setIcon(getIcon(feature));
    marker.setZIndexOffset(zIndexOffset);
    marker.on("mouseout", () => {
      externalHighlight = true;
      marker.setIcon(getIcon(feature));
      dispatch("featureHovered");
    });
    return handleHoverAndClick(marker, feature);
  };
  const shortlistedFeatureToLayer = (feature, latlng) => {
    const zIndexOffset = 610;
    let marker = L.marker(latlng);
    marker.setIcon(getShortlistedIcon(feature));
    marker.setZIndexOffset(zIndexOffset);
    marker.on("mouseout", () => {
      dispatch("featureHovered");
      marker.setIcon(getShortlistedIcon(feature));
    });
    return handleHoverAndClick(marker, feature);
  };
  const highlightedFeatureToLayer = (feature, latlng) => {
    const zIndexOffset = 620;
    let marker = L.marker(latlng);
    marker.setIcon(getHighlightedIcon(feature));
    marker.setZIndexOffset(zIndexOffset);
    return handleHoverAndClick(marker, feature);
  };
  const displayFeatures = () => {
    if (featuresLayer) featuresLayer.clearLayers();
    if (features.length > 0) {
      // draw main map
      featuresLayer = L.geoJSON(features, {
        // filter out items with no geolocation data, lest they would break map render
        filter: function (feature) {
          if (!feature.geometry.coordinates[0]) return false;
          return true;
        },
        pointToLayer: featureToLayer,
      });
      featuresLayer.addTo(map);
      // draw minimap
      let pubs2 = L.geoJson(features, {
        filter: function (feature) {
          if (!feature.geometry.coordinates[0]) return false;
          return true;
        },
        pointToLayer: function (feature, latlng) {
          // return new L.CircleMarker(latlng, { radius: 1, color: "#f0b054" });
          return new L.CircleMarker(latlng, {
            radius: 1,
            color: "#f5a020",
            // color: shortlistedFeatures.some((item) => {
            //   item.properties.id === feature.properties.id;
            // })
            //   ? "#f5a020"
            //   : "#545bd2",
          });
        },
      });
      let layers = new L.LayerGroup([miniMapTile, pubs2]);
      miniMap.changeLayer(layers);
    } else {
      // clear up minimap if no features are provided
      let layers = new L.LayerGroup([miniMapTile, L.geoJson([])]);
      miniMap.changeLayer(layers);
    }
  };
  const displayShortlistedFeatures = () => {
    if (shortlistedFeaturesLayer) shortlistedFeaturesLayer.clearLayers();
    if (shortlistedFeatures.length > 0) {
      shortlistedFeaturesLayer = L.geoJSON(shortlistedFeatures, {
        // filter out items with no geolocation data, lest they would break map render
        filter: function (feature) {
          if (!feature.geometry.coordinates[0]) return false;
          return true;
        },
        pointToLayer: shortlistedFeatureToLayer,
      });
      shortlistedFeaturesLayer.addTo(map);
    }
  };
  const displayHighlightedFeature = () => {
    if (highlightedFeatureLayer) highlightedFeatureLayer.clearLayers();
    if (highlightedFeature) {
      highlightedFeatureLayer = L.geoJSON(highlightedFeature, {
        // filter out items with no geolocation data, lest they would break map render
        filter: function (feature) {
          if (!feature.geometry || !feature.geometry.coordinates[0])
            return false;
          return true;
        },
        pointToLayer: highlightedFeatureToLayer,
      });
      highlightedFeatureLayer.addTo(map);
    }
  };

  onMount(async () => {
    await import("leaflet");
    await import("leaflet/dist/leaflet.css");
    await import("leaflet-minimap");
    await import("leaflet-minimap/dist/Control.MiniMap.min.css");
    await import("leaflet-gesture-handling");
    await import("leaflet-gesture-handling/dist/leaflet-gesture-handling.css");
    // await import("leaflet.markercluster");
    initializeMap();
    mounted = true;
  });

  $: minimapW = Math.min(viewportWH.w / 3, 200);

  $: {
    features;
    if (mounted) displayFeatures();
  }
  $: {
    shortlistedFeatures;
    if (mounted) displayShortlistedFeatures();
  }
  $: {
    highlightedFeature;
    if (mounted && externalHighlight) displayHighlightedFeature();
  }
</script>

<map class="mapdiv" id="mapdiv" />

<style>
  .mapdiv {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
  }
  :global(.markerIcon) {
    /* height: 26px;
    width: 26px; */
    background-image: url(/kategorije/16x16px/orange/pin.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    font-style: italic;
    padding-top: 2px;
  }
  :global(.highlightedMarkerIcon) {
    /* height: 26px;
    width: 26px; */
    background-image: url(/kategorije/16x16px/red/pin.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    font-style: italic;
    padding-top: 2px;
  }
</style>
