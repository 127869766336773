<script>
  import { append } from "ramda/src";

  import { getEventCategoriesFromDB } from "../../pages/EventEditor";

  export let categoriesInDataset = [];
  export let categoriesFilter = [];
  let allCategories = getEventCategoriesFromDB();

  const toggleCategoryFiltered = (category) => {
    categoriesFilter = categoriesFilter.includes(category)
      ? categoriesFilter.filter((item) => item !== category)
      : append(category, categoriesFilter);
  };

  categoriesFilter = ["f", "e", "d", "c", "b", "a"];
</script>

<div class="frame">
  {#each allCategories as category}
    <div
      on:click={() => toggleCategoryFiltered(category.name)}
      class="category {categoriesInDataset.includes(category.name)
        ? 'active'
        : ''}"
      style="opacity: {categoriesInDataset.includes(category.name)
        ? categoriesFilter.includes(category.name)
          ? '1'
          : '0.5'
        : '0.2'}"
      alt={category.description}
      title={category.description}
    >
      {category.name.toUpperCase()}
    </div>
  {/each}
</div>

<style>
  .frame {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    background-color: #ecf0f1;
    padding: 5px 5px 0;
  }
  .frame .category {
    height: 24px;
    width: 24px;
    background-image: url(/kategorije/16x16px/orange/pin.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
    font-style: italic;
    padding-top: 4px;
  }

  /* .frame .category img {
    height: 25px;
    width: 25x;
  } */
  .active {
    cursor: pointer;
  }
</style>
