<script>
  import EventCard from "./EventCard.svelte";

  import { differenceInCalendarDays, add } from "date-fns";

  import {
    checkIfEventIsHighlighted,
    checkIfEventIsShortlisted,
  } from "./EventsList";

  export let totalEventsListColumns;
  export let events = [];
  export let shortlistedEvents;
  export let highlightedEvent;
  // let frameStyle;

  const highlightEvent = (e) => {
    highlightedEvent = e.detail;
  };

  $: gridColumns = `grid-template-columns: repeat(${totalEventsListColumns}, 1fr);`;
</script>

<div>
  {#if events && events.length > 0}
    <div class="grid" style={gridColumns}>
      {#each events as event}
        <EventCard
          {event}
          isHighlighted={checkIfEventIsHighlighted(event, highlightedEvent) ||
            checkIfEventIsShortlisted(event, shortlistedEvents)}
          on:highlightEventRequested={highlightEvent}
          on:highlightBrandRequested
          on:selectEventRequested
          on:toggleFollowEventRequested
        />
      {/each}
    </div>
  {:else}
    <p style="text-align: center; padding: 10px; font-weight: bolder;">
      No event matches the set criteria
    </p>
  {/if}

  <div class="bottom-line">
    <div style="width: 100%">
      <p style="text-align: center; font-size: 12px">
        <b style="font-size: 12px">credits:</b>
        Flag icons made by
        <a
          href="https://www.flaticon.com/authors/freepik"
          title="Freepik"
          style="font-size: 12px"
        >
          Freepik
        </a>
        from
        <a
          href="https://www.flaticon.com/"
          title="Flaticon"
          style="font-size: 12px"
        >
          www.flaticon.com
        </a>
        {" / "}
        Other icons by
        <a
          href="https://thenounproject.com/"
          title="Noun project"
          style="font-size: 12px"
        >
          The Noun Project
        </a>
        collaborators
      </p>
    </div>
  </div>
</div>

<style>
  .grid {
    padding: 10px;
    display: grid;
  }
  .bottom-line {
    min-height: 20px;
    padding: 5px;
    background-color: var(--color-foreg-shadow);
  }
</style>
