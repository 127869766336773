<script>
  import { createEventDispatcher } from "svelte";

  import { getEventBrandsFromDB } from "../EventEditor/index";
  import { append } from "ramda/src";

  export let brandsFilter = [];

  let brands = getEventBrandsFromDB();

  const dispatch = createEventDispatcher();

  const toggleBrandsFiltered = (brand) => {
    brandsFilter = brandsFilter.includes(brand.id)
      ? brandsFilter.filter((id) => id !== brand.id)
      : append(brand.id, brandsFilter);
    console.log(brandsFilter);
  };

  const requestHighlightBrand = (brand) => {
    dispatch("highlightBrandRequested", brand);
  };
  const requestUnhighlightBrand = () => {
    dispatch("highlightBrandRequested");
  };
</script>

<style>
  .frame {
    flex: 1;
    padding: 1px;
    display: flex;
    overflow-x: scroll;
    align-items: center;
  }
  .frame .brand-item {
    height: 30px;
    width: 28px;
    border: 1px solid red;
    border-radius: 3px;
    margin: 0px 2px;
    overflow: hidden;
    cursor: pointer;
  }

  .frame .isNotSelected {
    background: black;
  }
  .frame .isSelected {
    background: yellow;
  }
</style>

<div class="frame">
  {#each brands as brand}
    <div
      class="brand-item {brandsFilter.includes(brand.id) ? 'isSelected' : 'isNotSelected'}"
      on:mouseenter={() => requestHighlightBrand(brand)}
      on:mouseleave={requestUnhighlightBrand}
      on:click={() => toggleBrandsFiltered(brand)}
      title="{brand.owner} - {brand[brand.pp]}">
      {brand[brand.pp]}<br />
    </div>
  {/each}
</div>
