<script>
  import DaysOfWeek from "./DaysOfWeek.svelte";
  import Month from "./Month.svelte";

  import {
    makeToday,
    getWeekendInNWeeks,
    makeMonths,
    makeMonthDaysSlots,
  } from "./index";

  let today = makeToday();
  /* calendar data */
  export let itemsByDate;
  export let dsaHoveredItemsByDate;
  export let listedItemsByDate;
  export let shortlistedItemsByDate;
  export let highlightedDateRange;
  export let year = 2025; //today.year;
  export let selectedDateRange;
  export let hoveredDate;
  /* render options */
  export let firstDayOfWeek = 1;
  export let forceFullWeeks = false;
  export let showDaysOfWeek = true;
  const totalMonthDaysSlots = forceFullWeeks ? 42 : 37;
  const monthDaysSlots = makeMonthDaysSlots(
    firstDayOfWeek,
    totalMonthDaysSlots
  );

  const selectPreviousYear = () => {
    year = year - 1;
  };
  const selectNextYear = () => {
    year = year + 1;
  };

  $: selectedDateRange = {
    ...selectedDateRange,
    [year]:
      selectedDateRange && selectedDateRange[year]
        ? selectedDateRange[year]
        : year === today.year
        ? {
            start: getWeekendInNWeeks(today.date, -1),
            end: getWeekendInNWeeks(today.date, 2),
          }
        : {
            start: 1,
            end: 20,
          },
  };
  $: months = makeMonths(year);
  $: isEvenYear = !(year % 2);
</script>

<div class="main">
  <div class="season-picker">
    {#if year > 2020}
      <div class="season unselected" on:click={selectPreviousYear}>
        {`${year - 1} <<`}
      </div>
    {/if}
    <div class="season selected">{year}</div>
    {#if year < 2025}
      <div class="season unselected" on:click={selectNextYear}>
        {`>> ${year + 1}`}
      </div>
    {/if}
  </div>
  <table>
    <thead>
      {#if showDaysOfWeek}
        <DaysOfWeek {monthDaysSlots} {firstDayOfWeek} />
      {/if}
    </thead>
    <tbody>
      {#each months as month}
        <Month
          {month}
          {monthDaysSlots}
          {firstDayOfWeek}
          {today}
          {itemsByDate}
          {dsaHoveredItemsByDate}
          {listedItemsByDate}
          {shortlistedItemsByDate}
          {isEvenYear}
          {highlightedDateRange}
          bind:selectedYearDateRange={selectedDateRange[year]}
          bind:hoveredDate
        />
      {/each}
    </tbody>
  </table>
</div>

<style>
  :root {
    --calendar-width: 739px;
  }
  .main {
    width: var(--calendar-width);
  }
  .season-picker {
    display: flex;
    justify-content: center;
    padding: 2px 0;
    user-select: none;
  }
  .season-picker .season {
    display: flex;
    width: 80px;
    font-weight: bold;
    justify-content: center;
  }
  .season-picker .unselected {
    color: lightslategray;
  }
  .season-picker .unselected:hover {
    color: darkslategrey;
  }
  .season-picker .season .unselected:hover .isInEvenYear {
    color: goldenrod;
  }
  .season-picker .selected {
    color: white;
    background-color: #f58b20;
    border-radius: 3px;
    /* flex: 1 0 auto; */
  }
  .season-picker .selected .isInEvenYear {
    color: goldenrod;
  }
  table {
    width: 100%;
    border: 1px solid slategray;
    padding: 0px;
    background: white;
    user-select: none;
  }
</style>
