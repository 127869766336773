<script>
  export let keywordFilter = "";

  let keyword = "";
  let minKeywordLength = 3;
  let previousKeywordLength = 0;
  let isIinitialKeywordFixed = false;

  let isInitialized = false;

  const checkKeywordChanged = () => {
    if (
      keyword.length + 1 === minKeywordLength &&
      keyword.length < previousKeywordLength
    ) {
      keywordFilter = "";
    }
    if (!(keyword.length < minKeywordLength)) {
      keywordFilter = keyword;
    }
    previousKeywordLength = keyword.length;
  };

  $: if (!isIinitialKeywordFixed && keywordFilter != "") {
    keyword = keywordFilter;
    isIinitialKeywordFixed = true;
  }
  $: minKeywordLength = keyword.includes(":") ? 4 : 3;
  $: if (!isInitialized && keywordFilter.length > 0) {
    previousKeywordLength = keywordFilter.length;
    isInitialized = true;
  }
</script>

<div class="frame">
  <div class="search-icon"><img src="icons/search.png" /></div>
  <input
    bind:value={keyword}
    on:input={checkKeywordChanged}
    type="text"
    class="text"
    class:text-active={keyword.length >= minKeywordLength}
    placeholder="Search by event title or venue"
  />
</div>

<style>
  .frame {
    --searchbox-ht: 33px;
    height: var(--searchbox-ht);
    background-color: #ecf0f1;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }
  .frame .search-icon {
    position: relative;
    height: 30px;
    width: 30px;
  }
  .frame .search-icon img {
    height: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .frame .text {
    width: 100%;
    font-size: 15px;
    margin: 0 5px 0 0;
    padding: 0;
    color: gray;
  }
  .frame .text-active {
    color: black;
  }
</style>
